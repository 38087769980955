<template>
  <!--专利超市-->
  <div class="div_center" style="background-color: #fafafa">
    <div style="padding-top: 45px;padding-bottom: 45px" class="center">
      <div
          style="width: 100%;background-color: white;border-width: 1px;border-color: rgb(232, 232, 232);border-style: solid;">
        <Row>
          <Col style="display: flex;flex-flow: column;justify-content: space-around;width: 240px" span="6">
            <TabFloorItem
                v-for="(item, index) in floorTopMap"
                :data="item"
                :key="item.id"
                @chooseItem="onTabItemChange"
                @tabIndexChange="onTabIndexChange"
                :config="{index, itemNum: floorTopMap.length, tabIndex}"
                @click.native="changelistIndex('goodsList', index)"
            />
          </Col>
          <Col span="18" style="width: 940px">
            <div class="rmzl_content_real" style="padding: 20px 20px 0 20px!important;width:940px;height: 450px;justify-content:flex-start;align-items: flex-start">
              <PGoodItemTop v-for="item in tabItem" :data="item" :type="'patent'" :detailUrlPath="'good'" :key="item.id"
                            style="width:287px!important;height: 195px!important;margin-bottom: 0!important;flex-shrink: 0"/>

            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div style="height: auto;background-color: white" class="div_center">
      <div class="center" v-for="(item,index) in floorMainMap" :key="item.floorType+ index" style="margin-bottom: 70px;">
        <div class="dpyx"
             style="position:relative;height: 135px;font-size: 28px;color: black;display: flex;align-items: center;justify-content: center">
          <div style="position: absolute;right: 0;top: 50%;transform: translateY(-50%)" v-if="item.floorType !== 'THIRD_PARTY_FLOOR'">
            <router-link :to="{path: getMorePath(item.floorType)}"
                         style="font-size:14px; color:#000;font-weight: normal;" target="_blank">更多
            </router-link>
          </div>
          <div style="position: absolute;right: 0;top: 50%;transform: translateY(-50%)" v-if="item.floorType == 'THIRD_PARTY_FLOOR'">
            <router-link :to="{path: getMorePath(item.floorType)}"
                         style="font-size:14px; color:#000;font-weight: normal;" target="_blank">更多
            </router-link>
          </div>
          <span
              style="display: inline-block;height: 5px;width: 5px;background-color: rgba(255,106,0,.4);;border-radius: 5px"></span>
          <span
              style="display: inline-block;height: 10px;width: 5px;background-color: rgba(255,106,0,.6);;border-radius: 5px"></span>
          <span
              style="display: inline-block;height: 15px;width: 5px;background-color: rgba(255,106,0,.8);;border-radius: 5px"></span>
          <span
              style="display: inline-block;height: 20px;width: 5px;background-color: rgba(255,106,0,1);;border-radius: 5px"></span>
          <span style="margin: 0 40px">{{ item.name }}</span>
          <span
              style="display: inline-block;height: 20px;width: 5px;background-color: rgba(255,106,0,1);border-radius: 5px"></span>
          <span
              style="display: inline-block;height: 15px;width: 5px;background-color: rgba(255,106,0,.8);;border-radius: 5px"></span>
          <span
              style="display: inline-block;height: 10px;width: 5px;background-color: rgba(255,106,0,.6);;border-radius: 5px"></span>
          <span
              style="display: inline-block;height: 5px;width: 5px;background-color: rgba(255,106,0,.4);;border-radius: 5px"></span>
        </div>
        <div class="rmzl_content_real"
             style="border:1px solid rgb(232,232,232);padding-top: 15px;justify-content: flex-start;">
          <PSGoodItem v-for="gItem in item.objs.length > 6?item.objs.slice(0,6):item.objs" :data="gItem" :key="gItem.id + item.floorType" :gType="item.floorType"
                      :detailUrlPath="getDetailPath(item.floorType)" :serviceNum="serviceNumMap(gItem.id)" style="margin-left:15px;margin-bottom: 0!important;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PGoodItemTop from "@/components/index/psGoodItemTop";
import PSGoodItem from "@/components/index/psGoodItem";
import TabFloorItem from "@/components/index/psTabFloorItem";
import {getPatentIndex} from "../../plugins/api/Good";
import {getServiceNum} from "@/plugins/api/scienceServiceApi";
import baseUrl from "../../plugins/http/baseUrl";
export default {
  name: "patentSupermarket",
  data() {
    return {
      floorTop: [],
      floorMain: [],
      icons: ["iconrmzl", "iconfmcz", "iconsyxx", "iconwgsj"],
      tabItem: [],
      tabIndex: 0,
      serviceNum:[],
    };
  },
  components: {
    TabFloorItem,
    PGoodItemTop,
    PSGoodItem
  },
  computed: {
    floorTopMap() {
      return this.floorTop.map((item, index) => {
        let data = Object.assign({}, item.config);
        return Object.assign(
            {icon: this.icons[index], showLabel: index !== 0, showSubTitle: index === 0}, data
        );
      });
    },
    floorMainMap() {
      let result = [];
      this.floorMain.forEach((item, index) => {
        let data = item.config;
        if (data && data.labels) {
          data.labels.forEach(label => {
            result.push(Object.assign({floorType: data.floorType}, label));
          })
        }
      });
      return result;
    }
  },
  mounted() {
    getPatentIndex().then((res) => {
      if (res.code === 0) {
        this.floorTop = res.result.floors.slice(0, 4);
        this.floorMain = res.result.floors.slice(4);
        let shopFloor = {};
        this.tabItem = this.floorTop[0]?.config?.labels[0]?.objs;
        res.result.floors.forEach((item,index) => {
          if(item.config.floorType == 'THIRD_PARTY_FLOOR') {
            shopFloor = item;
          }
        })
        let id = "";
        shopFloor.config.labels[0].objs.forEach((item,index) => {
          id += item.id + ','
        })
        id = id.slice(0,id.length-1)
        if(baseUrl.indexOf("minwf.cn") > -1){
          getServiceNum({shopIds:id}).then(res=> {
            if(res.code ==0 ){
              this.serviceNum = res.result
            }

          })
        }

      }
    });
  },
  methods: {

    serviceNumMap(id){
      let count = 0 + ''
      this.serviceNum.map((item,index) => {
        if(item.shopId == id){
          count = item.serviceCount + ''
        }
      })
      return count
    },
    changelistIndex(pagename, i) {
      // this.$router.push({
      //   path: 'goodsList',
      //   query: {
      //     patentType: i
      //   }
      // })
    },
    getMorePath(type) {
      if (type === 'PATENT_GOODS_FLOOR') {
        return '/home/goodsList';
      } else if(type =='SHOP_FLOOR'){
        return '/home/storeList?tab=2';
      }else if(type == 'THIRD_PARTY_FLOOR'){
        return '/home/storeList?tab=7';
      } else{
        return '/home/storeList?tab=1'
      }
    },
    getDetailPath(type) {
      if (type === 'PATENT_GOODS_FLOOR') {
        return 'good?goodId=';
      } else {
        return 'OutsideStore?shopId=';
      }
    },
    onTabItemChange(item) {
      this.tabItem = item;
    },
    onTabIndexChange(index) {
      this.tabIndex = index;
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .ivu-page-item-active {
  background-color: #1890FF;
  color: white !important;
  border: none;
}

/deep/ .ivu-page-item-active a {
  color: white !important;
}

.iconfont {
  font-size: 13px;
  vertical-align: -0.05rem !important;
}

.zl_header .per_header:nth-child(3), .zl_header .per_header:nth-child(6) {
  border-right: 1px solid rgb(232, 232, 232) !important;
}

.left_desc {
  padding-left: 17px;

  & span {
    line-height: 30px;
  }
}

#order > span {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.per_header {
  padding: 20px;
}

.zl_header .per_header:nth-child(4), .zl_header .per_header:nth-child(5), .zl_header .per_header:nth-child(6) {
  margin-top: 4px !important;
}

.service_div {
  font-size: 14px;
  line-height: 50px;

  & span {
    display: inline-block;
    height: 50px;
    margin-right: 40px;
    cursor: pointer;
    color: #666;
  }
}
.rmzl_content_real > div.good-con:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
}
.rmzl_content_real > div:not(.good-con) {
  margin-bottom: 0!important;
}
.rmzl_content_real > div:not(.good-con):hover{
  box-shadow: none!important;
}
.rmzl_content_real {
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;

}

.dpyx {
  & span {
    margin: 0 2px;
  }
}

.san_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.liang_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.si_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.rmzl_title_price .desc {
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;;
  overflow: hidden;
}

.rmzl_image {
  width: 150px;
  height: 180px;
  float: left;
}

.rmzl_title_price {
  width: 228px;
  height: 180px;
  line-height: 1;
  float: left;
  box-sizing: border-box;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;

}

.div_center {
  width: 100%;
}

.center {
  width: 1200px;
  margin: 0 auto;
}

.select_span {
  color: #FF6A00 !important;
  border-bottom: 1px solid #FF6A00;
}

.active {
  color: #FF6A00;
}

.active span:last-child {
  color: #333;
}

.activeASC span:first-child {
  color: #333;
}

.activeASC span:last-child {
  color: #FF6A00;
}

.rwjs {
  width: 575px;
  height: 280px;
  border: 1px solid #f5f5f5;
  flex-shrink: 0;
  cursor: pointer;
}

.rwjs_sub div {
  margin-bottom: 10px;
}
.ivu-avatar>img {
  height: 100%!important;
}
.rwtx {
  width: 148px;
  float: left;
  height: 100%;
}
</style>

