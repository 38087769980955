<template>
  <!--专家-->
  <div class="center" style=";overflow: hidden;margin-top: 15px;font-size: 14px">
    <div style="float: left;margin-right: 15px">
      <div style="width: 220px;background-color: white">
        <div style="min-height: 230px;text-align: center">
          <Avatar icon="ios-person" :src="shopDetal.imageUrl" size="70" style="margin: 30px auto"/>
          <div style="margin-bottom: 30px">
            <span style="font-size: 18px;color: black">{{shopDetal.name}}</span>
          </div>
          <div @click="returnRoom()"
               style="height: 30px;width: 80px;margin: 20px auto;cursor: pointer;line-height: 30px;background-color: rgb(255, 106, 0);color: white">
            咨询
          </div>
        </div>
        <div style="padding: 0 15px">
          <div style="margin-bottom: 15px"><span style="font-size: 14px;color: black">专家简介:</span></div>
          <div>
                <span>
                  {{shopDetal.introduction || '暂无信息'}}
                </span>
          </div>
        </div>
      </div>
      <div style="margin-top: 15px" v-if="commentList.length > 0">
        <div
          style="height: 40px;border-bottom: 1px solid rgb(232, 232, 232);line-height: 40px;background-color: white;padding: 0 15px">
          <span style="font-size: 16px">店铺答疑</span>
        </div>
        <div v-for="(item,index) in commentList" :key="index"
             style="padding:20px 15px;width:220px;background-color: white;border-bottom: 1px solid rgb(232, 232, 232)">
          <div><span style="font-size: 14px;color: black">{{item.reviewContent}}?</span></div>
          <div><span>
                {{item.replyContent}}
              </span></div>
        </div>
        <div
          style="line-height:54px;height: 54px;width: 220px;padding:0 15px;background-color: white;;text-align: right">
              <span @click="lastpre()"
                style="line-height:30px;text-align:center;color:black;display: inline-block;height: 30px;width: 30px;background-color:#F1F2F6;border-radius: 2px;cursor: pointer"><</span>
          <span @click="nextpre()"
            style="line-height:30px;text-align:center;color:black;display: inline-block;height: 30px;width: 30px;background-color:#F1F2F6;border-radius: 2px ;cursor: pointer">></span>
        </div>
      </div>
    </div>
    <div style="float: left;width: 965px">
      <div style="margin-bottom: 40px">
        <div>
          <div style="height: 50px;line-height: 50px;padding: 0 10px;background-color: white">
            <div style="float: left">
              <span>店铺专利</span>
            </div>
          </div>
        </div>
        <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;">
          <div style="height: 380px;background-color:white;width: 230px;margin: 10px 5px 0"
               v-for="(item,index) in patentList" :key="index" class="per-detail-hover" @click="seeDetail(item)">
            <div style="height: 200px">
              <img src="~@/assets/image/patent/patent-default.png" style="height: 200px;width: 230px" alt="">
            </div>
            <div style="height: 180px;padding: 0 10px">
              <div style="height: 60px;line-height: 60px">
                    <span style="font-size: 20px;color: rgb(255, 106, 0);
">暂无报价</span>
              </div>
              <div style="height: 40px">
                  <span style="font-size: 14px;line-height: 20px;color: black;" class="liang_hang_sheng">
                    <div v-html="item.ti" class="html-box"></div>
                  </span>
              </div>
              <div style="font-size: 12px;margin: 10px 0;overflow: hidden;height: 20px">
                <div style="float: left"><span>{{item.ph}}</span></div>
                <div style="float: right;color: rgb(255, 106, 0);
">{{item.type}}
                </div>
              </div>

              <div style="font-size: 12px;margin: 20px 0;overflow: hidden;display: flex;justify-content: space-between">
                <div class="msgbtn" @click.stop="openZoosUrl()">
                  立即咨询
                </div>
                <div class="msgbtn" @click.stop="seeDetail(item)" style="
    border: 1px solid #ff6a00;
    color: #ff6a00;">
                  查看详情
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 70px">
          <Page :total="totalNum" :page-size="patentparams.pageSize" show-elevator
                style="margin-top: 20px;float: right;" @on-change="changePage"/>
        </div>

      </div>


    </div>
  </div>

</template>

<script>
  import {
    getpatentbyexpertList,
    getshopDetail,
    sendComment,
    shopCommentList
  } from "@/plugins/api/Shop";
  import {checkLogin} from "@/plugins/api/loginApi";
  import {successToast} from "@/plugins/tools/util";

  export default {
    name: "experts",
    data() {
      return {
        shopId: '',
        patentparams: {
          pageSize: 16,
          pageNum: 1,
        },
        patentList: [],
        totalNum: 0,
        shopDetal: [],
        textareaVal: '',
        commentparams: {
          pageNum: 1,
          pageSize: 8,
        },
        commentList: [],
        commentListTotal: 0
      }
    },
    watch: {
      $route: {
        immediate: true, // 一旦监听到路由的变化立即执行
        handler(to, from) {
          if (JSON.stringify(to.params) !== '{}') {
            if (!to.query.shopId) {
              this.$router.push({name: 'index'})
            }
            this.shopId = to.query.shopId
            this.getpatentbyexpertList()
            this.getshopDetail()
            this.shopCommentList()
          }
        },
      }
    },
    methods: {
      seeDetail(item){
        let routeUrl = this.$router.resolve({
          path: "/home/ResourceDetail",//新页面地址
          query: { an: item.an }//携带的参数
        });
        window.open(routeUrl.href, "_blank");
      },
      shopCommentList() {
        shopCommentList({shopId: this.shopId, ...this.commentparams}).then(res => {
          if(res.code === 0){
            this.commentListTotal = res.result.total;
            this.commentList = res.result.list

          }
        })
      },
      //咨询
      openZoosUrl() {
        openZoosUrl('chatwin');
      },
      getpatentbyexpertList() {
        getpatentbyexpertList({'shopId': this.shopId, ...this.patentparams}).then((res) => {
          if (res.code === 0) {
            this.patentList = res.result.list
            this.totalNum = res.result.total
          }
        })
      },
      //专家详情
      getshopDetail() {
        getshopDetail({
          shopId: this.shopId
        }).then((res) => {
          if (res.code === 0 && res.result != null) {
            this.shopDetal = res.result
          }
        })
      },
      // 翻页
      changePage(index) {
        this.patentparams.pageNum = index;
        this.getpatentbyexpertList();
      },
      //咨询弹窗
      returnRoom: function () {
        const state = checkLogin(this.$router)
        if (!state) {
          return
        }

        this.$Modal.confirm({
          draggable: true,
          onOk: async () => {
            this.sendComment()
          },
          render: (h) => {
            return h('div',
              [h('div', {
                style: "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;",
              }, '咨询内容'),
                h('Input', {
                  style: "width:100%;",
                  props: {
                    value: this.textareaVal,
                    autofocus: true,
                    placeholder: '请输入咨询内容',
                    type: 'textarea',
                    autosize: true
                  },
                  on: {
                    input: (val) => {
                      this.textareaVal = val;
                    }
                  }
                })
              ])
          }
        })
      },
      //发送咨询
      sendComment() {
        sendComment({
          shopId: this.shopId,
          reviewContent: this.textareaVal
        }).then(res => {
          if (res.code === 0){
            this.textareaVal = ""
            successToast("咨询成功")
          }

        })
      },
      //咨询上一页
      lastpre() {
        if (this.commentparams.pageNum > 1) {
          this.commentparams.pageNum = this.commentparams.pageNum - 1
          this.shopCommentList()
        }
      },
      //咨询下一页
      nextpre() {
        if (this.commentparams.pageNum * this.commentparams.pageSize <= this.commentListTotal) {
          this.commentparams.pageNum = this.commentparams.pageNum - 0 + 1
          this.shopCommentList()
        }
      },
    }
  }
</script>

<style scoped>
  .msgbtn {
    margin: 0 auto;
    height: 25px;
    width: 90px;
    text-align: center;
    line-height: 25px;
    border: 1px solid black;
    cursor: pointer;
  }
  .msgbtn:hover {
    background-color: #ff6a00;
    color: white!important;
    border: 1px solid #ff6a00;
  }
  .per-detail-hover:hover {
    box-shadow: 6px 6px 20px -6px rgba(0, 0, 0, 0.3);
  }
  /*.msgbtn:hover {*/
  /*  background: #C51D2B;*/
  /*  border: 1px solid #C51D2B;*/
  /*  color: #fff;*/
  /*}*/

  .liang_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
<style>
  textarea.ivu-input {
    height: 120px !important;
  }
</style>
