<template>
  <!--商品列表-->
  <div style="width: 100%;background-color: #FAFAFA">
    <div class="center" style="padding-top: 10px">
      <div style="line-height: 50px;background-color: white" class="service_div">
        <div
            style="width: 1180px;margin: 0 auto;height: 100%;border-bottom: 1px solid rgb(220, 220, 220);display: flex;position: relative">
          <span style="margin-right: 70px;color:#333;">相关分类</span>
          <div style="width: 1000px">
            <div @click="selectClassifyOne(-1)"
                 :class="{'select_span':classifyOneChoice == -1}"
                 style="float: left;margin-right: 40px;"
            >全部分类
            </div>
            <div style="float: left;width: 880px">
            <span v-for="(item,index) in classify" :key="index"
                  @click="selectClassifyOne(index)"
                  :class="{'select_span':classifyOneChoice == index}"
                  v-show="index < 9 || isallclassify"
            >{{ item.name }}</span>
            </div>
          </div>
          <div @click="changeclassifyshow()" v-show="classify.length >= 9 && !isallclassify "
               style="position: absolute;right: 10px;cursor: pointer">展开
          </div>
          <div @click="changeclassifyshow()" v-show="classify.length >= 9 && isallclassify"
               style="position: absolute;right: 10px;cursor: pointer">收起
          </div>
        </div>
      </div>
      <!--            二级分类-->
      <div v-show="classifyTwo.length" style="line-height: 50px;background-color: white" class="service_div">
        <div
            style="width: 1180px;margin: 0 auto;height: 100%;border-bottom: 1px solid rgb(220, 220, 220);display: flex;position: relative">
          <span style="margin-right: 70px;color:#333;">二级分类</span>
          <div style="width: 1000px">
            <div @click="selectClassifyTwo(-1)"
                 :class="{'select_span':classifyTwoChoice == -1}"
                 style="float: left;margin-right: 40px;"
            >全部分类
            </div>
            <div style="float: left;width: 880px">
              <span v-for="(item,index) in classifyTwo" :key="index"
                    @click="selectClassifyTwo(index)"
                    :class="{'select_span':classifyTwoChoice == index}"
                    v-show="index < 9 || isallclassify"
              >{{ item.name }}</span>
            </div>
          </div>
          <div @click="changeclassifyshow()" v-show="classifyTwo.length >= 9 && !isallclassify "
               style="position: absolute;right: 10px;cursor: pointer">展开
          </div>
          <div @click="changeclassifyshow()" v-show="classifyTwo.length >= 9 && isallclassify"
               style="position: absolute;right: 10px;cursor: pointer">收起
          </div>
        </div>
      </div>
      <!--            三级分类-->
      <div v-show="classifyThree.length" style="line-height: 50px;background-color: white" class="service_div">
        <div
            style="width: 1180px;margin: 0 auto;height: 100%;border-bottom: 1px solid rgb(220, 220, 220);display: flex;position: relative">
          <span style="margin-right: 70px;color:#333;">三级分类</span>
          <div style="width: 1000px">
            <div @click="selectClassifyThree(-1)"
                 :class="{'select_span':classifyThreeChoice == -1}"
                 style="float: left;margin-right: 40px;"
            >全部分类
            </div>
            <div style="float: left;width: 880px">
               <span v-for="(item,index) in classifyThree" :key="index"
                     @click="selectClassifyThree(index)"
                     :class="{'select_span':classifyThreeChoice == index}"
                     v-show="index < 9 || isallclassify"
               >{{ item.name }}</span>
            </div>
          </div>
          <div @click="changeclassifyshow()" v-show="classifyThree.length >= 9 && !isallclassify "
               style="position: absolute;right: 10px;cursor: pointer">展开
          </div>
          <div @click="changeclassifyshow()" v-show="classifyThree.length >= 9 && isallclassify"
               style="position: absolute;right: 10px;cursor: pointer">收起
          </div>
        </div>
      </div>
      <div style="height: 50px;background-color: white" class="service_div">
        <div style="width: 1180px;margin: 0 auto;height: 100%;border-bottom: 1px solid rgb(220, 220, 220)">
          <span style="margin-right: 70px;color:#333;">专利类型</span> <span v-for="(item,index) in patentType"
                                                                         :key="index"
                                                                         @click="selectPatentType(index)"
                                                                         :class="{'select_span':patentTypeChoice == index}">{{ item.name }}</span>
        </div>
      </div>
      <div style="height: 50px;background-color: white" class="service_div">
        <div style="width: 1180px;margin: 0 auto;height: 100%;">
          <span style="margin-right: 70px;color:#333;">交易类型</span> <span v-for="(item,index) in serviceType"
                                                                         :key="index"
                                                                         @click="selectServiceType(index)"
                                                                         :class="{'select_span':serviceTypeChoice == index}">{{ item.name }}</span>
        </div>
      </div>
      <div class="center" id="order"
           style="margin-top: 10px;background-color: white;height: 50px;line-height: 50px;font-size: 14px">
              <span style="padding-left: 10px;" @click="chooseMoRen()"
                    :class="{'active' : params.sortField==''}">默认</span>
        <span @click="choosePRICE()"
              :class="[{'active' : params.sortField=='PRICE'},{'activeASC' : params.sortField=='PRICE' && params.sortOrder=='ASC'}]">价格<span><icon
            class="iconfont iconjiang"></icon></span><span><icon
            class="iconfont iconsheng"></icon></span></span>
        <span @click="chooseATTENTION()"
              :class="[{'active' : params.sortField=='ATTENTION'},{'activeASC' : params.sortField=='ATTENTION' && params.sortOrder=='ASC'}]">关注度<span><icon
            class="iconfont iconjiang"></icon></span><span><icon
            class="iconfont iconsheng"></icon></span></span>
        <span @click="chooseUPEDTIME()"
              :class="[{'active' : params.sortField=='UPEDTIME'},{'activeASC' : params.sortField=='UPEDTIME' && params.sortOrder=='ASC'}]">上架时间<span><icon
            class="iconfont iconjiang"></icon></span><span><icon
            class="iconfont iconsheng"></icon></span></span>
      </div>
      <div style="display: flex;flex-wrap: wrap;justify-content: flex-start;">
        <div style="height: 380px;background-color:white;width: 230px;margin: 10px 5px 0"
             v-for="(item,index) in goodsList" :key="index" class="good_choice">
          <div style="height: 200px;cursor: pointer;" @click="checkGood(item.id)" >
            <img style="width: 230px;height: 200px;" :src="item.mainImageUrl"></img>
          </div>
          <div style="height: 180px;padding: 0 10px">
            <div style="height: 60px;line-height: 60px">
                    <span style="font-size: 20px;color: rgb(255, 106, 0);
">￥{{ item.price }}</span>
            </div>
            <div style="cursor: pointer;" @click="checkGood(item.id)">
                  <span style="font-size: 14px;line-height: 20px;color: black;height: 40px" class="liang_hang_sheng">
                    {{ item.name }}
                  </span>
            </div>
            <div style="font-size: 12px;margin: 10px 0;overflow: hidden">
              <div style="float: left"><span>{{ item.shopName }}</span></div>
              <div style="float: right;color: rgb(255, 106, 0);
">{{ item.patentType }}
              </div>
            </div>

            <div style="font-size: 12px;margin: 10px 0;overflow: hidden">
              <div class="consult" @click="openZoosUrl()">
                立即咨询
              </div>
              <div class="buybtn" @click="jumpAction(1,item.id)" style="color: #ff6a00 ">立即购买
              </div>
            </div>
          </div>
        </div>
      </div>
      <div STYLE="overflow: hidden">
        <Page :total="totalNum" :page-size="params.pageSize" show-elevator
              style="margin-top: 30px;float: right;margin-bottom: 30px" @on-change="changePage"/>
      </div>
    </div>

  </div>
</template>

<script>
import {
  getClassify,
  getGoodsList,
  goodsRest
} from "@/plugins/api/Good";
import {errorToast} from "../../plugins/tools/util";

export default {
  name: "goodList",
  data() {
    return {
      notFirst: true,
      patentType: [
        {type: '', name: '全部分类'},
        {type: 'INVENTION', name: '发明专利'},
        {type: 'UTILITY', name: '实用新型'},
        {type: 'APPEARANCE', name: '外观设计'}
      ],

      serviceType: [
        {type: '', name: '全部分类'},
        {type: 'TRANSFER', name: '转让'},
        {type: 'LICENSE', name: '许可'}
      ],
      params: {
        pageSize: 15,
        pageNum: 1,
        code: '',
        mode: '', //交易类型(TRANSFER:转让;LICENSE:许可)
        patentType: '', //专利类型(INVENTION:发明专利;UTILITY:实用新型;APPEARANCE:外观设计)
        sortField: '', //排序字段（PRICE:价格;ATTENTION:关注度;UPEDTIME:上架时间）
        sortOrder: '',  //排序方式（DESC:降序;ASC:升序）
      },
      totalNum: 0,
      goodsList: [],
      classify: [],
      classifyTwo: [],
      classifyThree: [],
      classifyOneChoice: -1,
      classifyTwoChoice: -1,
      classifyThreeChoice: -1,
      patentTypeChoice: 0,
      serviceTypeChoice: 0,
      isallclassify: false,//相关分类是否展开
    }
  },
  mounted() {
    this.getClassify()
    this.getGoodsList()
  },
  methods: {
    //展开收起
    changeclassifyshow() {
      this.isallclassify = !this.isallclassify
    },
    //在线咨询
    openZoosUrl() {
      openZoosUrl('chatwin');
    },
    //商品列表分类
    getClassify() {
      getClassify({type: 'PATENT'}).then(res => {
        if (res.code === 0) {
          this.classify = res.result
          if (this.$route.query.code) {
            for (let i = 0; i < this.classify.length; i++) {
              if (this.classify[i].code == this.$route.query.code) {
                this.classifyOneChoice = i
                this.getGoodsList();
                break;
              }
            }
          }
        }
      })
    },
    //商品列表
    getGoodsList() {
      let params = {}
      for (let i in this.params) {
        if (this.params[i]) {
          params[i] = this.params[i]
        }
      }
      if (this.$route.query.code && this.notFirst == true) {
        params.code = this.$route.query.code
      }
      getGoodsList(params).then(res => {
        if (res.code === 0) {
          this.goodsList = res.result.list
          this.totalNum = res.result.total
        }
      })
    },
    // 翻页
    changePage(index) {
      this.params.pageNum = index;
      this.getGoodsList();
    },
    //相关分类index-1
    selectClassifyOne(index) {
      this.notFirst = false;
      this.classifyOneChoice = index
      this.classifyTwoChoice = -1;
      this.classifyThreeChoice = -1;
      this.classifyTwo = [];
      this.classifyThree = [];
      this.params.code = ''
      if (index >= 0) {
        console.log(this.classify[index])
        this.params.code = this.classify[index].code
        if(this.classify[index].children){
          this.classifyTwo = this.classify[index].children
        }

      }
      this.getGoodsList()
    },
    //相关分类index-2
    selectClassifyTwo(index) {
      this.classifyTwoChoice = index
      this.classifyThreeChoice = -1;
      this.classifyThree = [];
      this.params.code = ''
      if (index >= 0) {
        this.params.code = this.classifyTwo[index].code
        this.classifyThree = this.classifyTwo[index].children
      }
      this.getGoodsList()
    },
    //相关分类index-3
    selectClassifyThree(index) {
      this.classifyThreeChoice = index
      this.params.code = ''
      if (index >= 0) {
        this.params.code = this.classifyThree[index].code
      }
      this.getGoodsList()
    },
    //专利index
    selectPatentType(index) {
      this.patentTypeChoice = index
      if (index > 0) {
        this.params.patentType = this.patentType[index].type
      } else {
        this.params.patentType = ''
      }
      this.getGoodsList()
    },
    //交易index
    selectServiceType(index) {
      this.serviceTypeChoice = index
      if (index > 0) {
        this.params.mode = this.serviceType[index].type
      } else {
        this.params.mode = ''
      }
      this.getGoodsList()
    },
    //跳转商品详情页
    checkGood(id) {
      this.$router.push({
        name: 'good',
        query: {
          goodId: id
        }
      })
    },
    //默认排序
    chooseMoRen() {
      this.params.sortField = '';
      this.params.sortOrder = '';
      this.getGoodsList();
    },
    //价格排序
    //排序字段（PRICE:价格;ATTENTION:关注度;UPEDTIME:上架时间）
    //排序方式（DESC:降序;ASC:升序）
    choosePRICE() {
      if (this.params.sortField == 'PRICE') {
        this.params.sortOrder = this.params.sortOrder == 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.params.sortField = 'PRICE';
        this.params.sortOrder = 'DESC'
      }

      this.getGoodsList();
    },
    chooseUPEDTIME() {
      if (this.params.sortField == 'UPEDTIME') {
        this.params.sortOrder = this.params.sortOrder == 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.params.sortField = 'UPEDTIME';
        this.params.sortOrder = 'DESC'
      }
      this.getGoodsList();
    },
    chooseATTENTION() {
      if (this.params.sortField == 'ATTENTION') {
        this.params.sortOrder = this.params.sortOrder == 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.params.sortField = 'ATTENTION';
        this.params.sortOrder = 'DESC'
      }
      this.getGoodsList();
    },
    // 立即购买
    async jumpAction(index, goodId) {
      let json = await goodsRest({goodsId: goodId, num: 1})
      if (json.code == 0) {
        if (json.result == true) {
          this.$router.push({
            name: 'good',
            query: {
              goodId: goodId,
              action: index
            }
          })
        } else {
          errorToast(json.message)
        }
      } else {
        errorToast(json.message)
      }

    }
  }
}
</script>


<style scoped lang="scss">
/deep/ .ivu-page-item-active {
  background-color: #1890FF;
  color: white !important;
  border: none;
}

/deep/ .ivu-page-item-active a {
  color: white !important;
}

.iconfont {
  font-size: 13px;
  vertical-align: -0.05rem !important;
}

.zl_header .per_header:nth-child(3), .zl_header .per_header:nth-child(6) {
  border-right: 1px solid rgb(232, 232, 232) !important;
}

.left_desc {
  padding-left: 17px;

  & span {
    line-height: 30px;
  }
}

#order > span {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.per_header {
  padding: 20px;
}

.zl_header .per_header:nth-child(4), .zl_header .per_header:nth-child(5), .zl_header .per_header:nth-child(6) {
  margin-top: 4px !important;
}

.service_div {
  font-size: 14px;
  line-height: 50px;

  & span {
    display: inline-block;
    height: 50px;
    margin-left: -4px!important;
    margin-right: 40px;
    cursor: pointer;
    color: #666;
  }
}

.rmzl_content_real {
  padding: 20px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;

}

.dpyx {
  & span {
    margin: 0 2px;
  }
}

.san_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.liang_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.si_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.rmzl_title_price .desc {
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;;
  overflow: hidden;
}

.rmzl_image {
  width: 150px;
  height: 180px;
  float: left;
}

.rmzl_title_price {
  width: 228px;
  height: 180px;
  line-height: 1;
  float: left;
  box-sizing: border-box;
  padding: 20px 15px;
}

.div_center {
  width: 100%;
}

.center {
  width: 1200px;
  margin: 0 auto;
}

.select_span {
  color: #FF6A00 !important;
  border-bottom: 1px solid #FF6A00;
}

.active {
  color: #FF6A00;
}

.active span:last-child {
  color: #333;
}

.activeASC span:first-child {
  color: #333;
}

.activeASC span:last-child {
  color: #FF6A00;
}

.consult {
  float: left;
  height: 25px;
  width: 80px;
  text-align: center;
  line-height: 25px;
  border: 1px solid black;
  cursor: pointer;
}

.consult:hover {
  background: #fc7c40;
  border: 1px solid #fc7c40;
  color: #fff;
}

.buybtn {
  float: right;
  height: 25px;
  width: 80px;
  color: rgb(255, 106, 0);
  text-align: center;
  line-height: 25px;
  border: 1px solid rgb(255, 106, 0);
  cursor: pointer;
}
.buybtn:hover {
  background-color: #ff6a00  ;
  color: white!important;
}
.good_choice:hover {
  box-shadow: 6px 6px 20px -6px rgba(0, 0, 0, .4);
}

</style>
