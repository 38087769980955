<!--
 * @Author: liu
 * @Date: 2021-03-31 11:22:33
 * @LastEditTime: 2021-04-02 11:58:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cuip-nuxt\components\index\ScienceServices\SGoodItem.vue
-->
<template>
  <div
    style="
      width: 100%;
      border-right: 1px solid rgb(232, 232, 232);
      flex: 1;
    "
    :class="['item-con', isChoosen ? 'white-color' : '']"
    @click="tabIndexChange"
  >
    <div
      style="
        height: 100%;
        width: 95%;
        margin: 0 auto;
        padding: 15px 10px;
      "
      :class="{'border-bottom': showItemBottomLine }"
    >
      <i :class="['iconfont', data.icon, isChoosen ? 'white-color' : '']" style="font-size: 20px"></i>
      <span :class="['title', isChoosen ? 'white-color' : '']" style="margin-left: 10px">{{data.name}}</span>
<!--      <div style="margin-left: 15px;margin-top: 5px">-->
<!--        <span :class="['sub-title', isChoosen ? 'white-color' : 'moren-color']" v-if="data.showSubTitle" style="margin-left: 10px;color:white;padding:0 5px;width:auto;border-bottom:1px solid white;cursor: pointer">{{data.subTitle}}</span>-->

<!--      </div>-->
<!--      <div class="label-list dp-flex flex-between flex-wrap" v-if="data.showLabel">-->
      <div class="label-list dp-flex flex-between flex-wrap">
        <span :class="{'choose_span':nowItem==item && isChoosen}" v-for="item in data.labels" :key="item.id" @click.stop="chooseItem(item)" style="cursor:pointer;margin-left: 8px">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "psTabFloorItem",
  data(){
    return {
      nowItem:"",
    }
  },
  mounted() {

    this.nowItem = this.data.labels[0]
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        name: "",
        subTitle: "",
        labels: [],
        icon: '',
        showLabel: true,
        showSubTitle: true
      })
    },
    config: {
      type: Object,
      default: () => ({
        index: 0,
        itemNum: -1,
        tabIndex: 0
      })
    }
  },
  computed: {
    showItemBottomLine() {
      let cf = this.config;
      return !this.isChoosen && cf.index !== cf.itemNum - 1
    },
    isChoosen() {
      return this.config.index === this.config.tabIndex
    }
  },
  methods: {
    chooseItem(item) {
      this.nowItem = item
      this.tabIndexChange();
      this.$emit('chooseItem', item.objs || []);
    },
    chooseItemFromTitle() {
      this.$emit('chooseItem', this.data.labels[0].objs || []);
    },
    tabIndexChange(event) {
      this.$emit('tabIndexChange', this.config.index);
      if(event) {
        this.chooseItemFromTitle();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.white-color{
  color: #fff;
}
.choose_span {
  color:white;
  border-bottom: 1px solid white;
}
.moren-color {
  color: rgb(102, 102, 102)!important;
}
.border-bottom{
  border-bottom: 1px solid rgb(232, 232, 232);
}
.item-con{
  &.white-color{
    background-color: rgb(255, 106, 0);
  }
}
.title{
  color: #000;
  &.white-color{
    color: #fff;
  }
}
.sub-title,.label-list{
  padding-left: 20px;
  padding-top: 5px;
  width: 240px;
}
</style>
