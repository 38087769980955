<!--
 * @Author: liu
 * @Date: 2021-03-31 11:22:33
 * @LastEditTime: 2021-04-01 16:25:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cuip-nuxt\components\index\ScienceServices\SGoodItem.vue
-->
<template>
  <div class="good-con" @click="goDetail(data.id)">
    <div class="rmzl_image" v-if="type!='patent'">
      <img :src="data.pic" style="width:100%; height:100%"/>
    </div>
    <div class="rmzl_title_price">
      <div style="color: #333333;height:42px;line-height: 20px" class="desc ellipse-2">
        {{data.name}}
      </div>
      <div>
        <div
            style="
            text-align: left;
            margin-top: 10px;
            line-height: 30px;
          "
            v-if="type === 'service'"
        >
<span style="color: #FDCE63">//////</span>
        </div>
        <div
            style="
            color: #ff6a00;
            font-size: 23px;
            text-align: left;
          "
        >
          <span>￥{{data.price}}</span>
        </div>
        <div v-if="type==='patent'" style="font-size:12px;color:#666666;margin-top: 10px;text-align: left;line-height: 20px">
          <div>专利申请号：{{data.patent.an}}</div>
          <div>专利类型：{{data.patent.type}}</div>
          <div class="ellipse-1">店铺：{{data.patent.pa}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SGoodItemTop",
  props: {
    data: {
      type: Object,
      default: () => ({
        id: 0,
        name: '',
        subTitle: '',
        pic: '',
        price: 0,
        patent: null
      })
    },
    detailUrlPath: {
      type: String,
      default: 'serviceDetail'
    },
    type: {
      type: String,
      default: 'service'
    }
  },
  methods:{
    goDetail(id) {
      this.$router.push({
        path: `${this.detailUrlPath}?goodId=${id}`,
      });
    }
  }
}
</script>

<style>
.good-con{
  border: 1px solid rgba(232, 232, 232, 0.5);
  display: flex;
  /*margin-bottom: 20px;*/
}
.good-con:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
}
.rmzl_title_price .desc {
  text-align: left;
}

.rmzl_image {
  width: 168px!important;
  height: 168px!important;
}

.rmzl_title_price {
  height: 152px!important;
  width: 210px!important;
  box-sizing: border-box!important;
  padding:12px 15px!important;
}
</style>
